<template>
    <div class="con-wrap">
        <CarrotTitle title="물품 예약"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <div class="mb-20">
                        <span class="float-right">＊필수 입력 항목입니다.</span>
                        <div class="clear"></div>
                    </div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 대여기간</th>
                                <td>
                                    <v-datepicker v-model="add.sdate" @dayclick="add.changeDate()" class="datepicker-group mr-10">
                                        <template #default="{ inputValue, togglePopover }">
                                            <div>
                                                <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                                                <button class="btn-cal" @click="togglePopover()"></button>
                                            </div>
                                        </template>
                                    </v-datepicker>
                                    ~
                                    <v-datepicker v-model="add.edate" @dayclick="add.changeDate()" class="datepicker-group ml-10">
                                        <template #default="{ inputValue, togglePopover }">
                                            <div>
                                                <input type="text" :value="inputValue" class="input w-80px" @click="togglePopover()" />
                                                <button class="btn-cal" @click="togglePopover()"></button>
                                            </div>
                                        </template>
                                    </v-datepicker>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 대여물품</th>
                                <td>
                                    <select name="cat1" id="cat1" class="w-200px mr-10" v-model="add.cat1" @change="add.setCat1">
                                        <option value="">중분류 선택</option>
                                        <option :value="irow" v-for="(irow, i) in add.categories" :key="i">{{ irow.name }}</option>
                                    </select>
                                    <select name="cat2" id="cat2" class="w-200px" v-model="add.cat2" @change="add.doSearch">
                                        <option value="">소분류 선택</option>
                                        <option :value="v" v-for="(v, k) in add.cat1.children" :key="k">{{ v }}</option>
                                    </select>
                                    <select name="prd" id="prd" class="w-200px ml-10" v-model="add.prd">
                                        <option value="">물품 선택</option>
                                        <option :value="v" v-for="(v, k) in add.products" :key="k">{{ v.title }}</option>
                                        <option value="" v-if="add.products.length==0">선택 가능한 물품이 없습니다.</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 사용목적</th>
                                <td>
                                    <input type="text" class="w-100per" v-model="add.use_memo">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 사용위치</th>
                                <td>
                                    <input type="text" class="w-100per" v-model="add.use_loc">
                                </td>
                            </tr>
                            <tr>
                                <th>비고</th>
                                <td>
                                    <textarea class="w-100per h-100px" v-model="add.etc_memo"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-left mt-30" @click="add.goList">목록</button>
                    <button class="btn-default float-right mt-30" @click="add.doSubmit">예약</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { DatePicker } from 'v-calendar'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'


export default {
    layout:"myPIMS",
    components: {
        'v-datepicker' : DatePicker 
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();

        const add = reactive({
            categories : [],
            products   : [],

            sdate : new Date(), edate : new Date(),
            cat1 : '', cat2 : '', prd : '',
            use_memo : '',
            use_loc  : '',
            etc_memo : '',

            changeDate : () => {
                if( add.sdate && add.edate && add.cat1.name && add.cat2 ){
                    add.doSearch();
                }
            },

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        add.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            setCat1 : () => {
                add.cat2 = "";
                add.products = [];
                add.prd = "";
            },

            doSearch : () => {
                let params = {
                    sdate : add.sdate,
                    edate : add.edate,
                    cat1  : add.cat1.name,
                    cat2  : add.cat2
                };

                if( add.edate < add.sdate ){
                    toast.error("대여기간을 정확히 입력하세요.");
                    add.products = [];
                    add.prd = "";
                    return;
                }

                axios.get("/rest/mypims/getRentalProd", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        add.products = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    sdate : add.sdate,
                    edate : add.edate,
                    cat2  : add.cat2,

                    use_memo : add.use_memo,
                    use_loc  : add.use_loc,
                    etc_memo : add.etc_memo
                };

                if( !params.sdate ){
                    toast.error("대여기간을 정확히 입력하세요.");
                    return;
                }

                if( !params.edate ){
                    toast.error("대여기간을 정확히 입력하세요.");
                    return;
                }

                if( typeof add.cat1 == 'undefined' || add.cat1 == '' || add.cat1.name == '' ){
                    toast.error("중분류를 선택하세요.");
                    return;
                } else {
                    params.cat1 = add.cat1.name;
                }

                if( params.cat2 == "" ){
                    toast.error("소분류를 선택하세요.");
                    return;
                }

                if( typeof add.prd == 'undefined' || add.prd == '' || add.prd.idx == '' ){
                    toast.error("대여 물품을 선택하세요.");
                    return;
                }
                params.prd = add.prd.idx;

                if( params.use_memo == "" ){
                    toast.error("사용목적을 입력하세요.");
                    return;
                }

                if( params.use_loc == "" ){
                    toast.error("사용위치를 입력하세요.");
                    return;
                }
                
                axios.post("/rest/mypims/addRental", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire("예약이 완료되었습니다.");
                        router.go(-1);
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            goList : () => {
                Swal.fire({
                    title : '물품예약',
                    text  : '입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        router.go(-1);
                    }
                });
            }
        });

        onMounted(() => {
            add.getCategory();
            console.log("반영테스트");
        });

        onUnmounted(() => {

        });

        return { add };
    }
}
</script>

<style lang="scss" scoped>
</style>